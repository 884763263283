import { NextPage } from 'next';

import { fetchSEOTags } from '../api/services';
import IWithSEO from '../types/IWithSEO';
import CarInspection from './car-inspection';

const Home: NextPage<IWithSEO> = ({ seo }) => {
  return <CarInspection seo={seo} />;
};

export async function getStaticProps() {
  return {
    props: {
      seo: await fetchSEOTags('/'),
    },
  };
}

export default Home;
